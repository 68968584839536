.slot {
  width: 100%;
}
.slot:hover {
  background-color: #9acfce;
}

.slot-selected {
  background-color: #1565c0 !important;
  color: White !important;
  font-weight: bolder;
}

.MuiPickersToolbar-penIconButton {
  display: none !important;
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText {
  font-weight: 900 !important;
  color: black;
}

.Calendar__monthText,
.Calendar__yearText {
  font-weight: 900 !important;
  color: black;
}

.Calendar__weekDay {
  font-weight: 900 !important;
  color: black;
}
.Calendar__day.-ltr.-selected {
  background-color: #1565c0;
}
