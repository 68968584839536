.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chatbot {
  width: 100% !important;
}
.rsc-content::-webkit-scrollbar {
  display: none;
}

.rsc-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.rsc-ts-user {
  text-align: left !important;
}

/* 
  This whole section is a hack since I can't figure out how to override the Options 
  output. 
*/
.rsc-os-option div {
  color: white !important;
  background: #1565C0 !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.rsc-os-option-image-text div {
  background-color: #1565C0 !important;
}

.rsc-os-option > div > div:first-child {
  display: none;
}

.rsc-os-option > div > div {
  display: flex;
  align-items: center;
}

.rsc-os-option > div > div > div {
  padding: 16px !important;
  width: 100% !important;
  box-sizing: border-box;
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 16px !important;
  letter-spacing: 0.45712px !important;
  line-height: 20px !important;
}

.rsc-cs input:not([type='checkbox']):not([type='submit']):not([type='button']):not([type='radio']):not([type='range']) {
  box-shadow: #CBC2B7 0px 0px 2px inset !important;
}
/* End Comment */