body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.inline-wrapper {
  border: 1px solid #c6c6cb;
  display: flex;
}
.inline-wrapper.field__has-error {
  border-color: #e1114e;
}
.inline-wrapper > div {
  display: flex;
}

#expiry,
.inline-wrapper
  .secure-field.secure-field__base.secure-field__input
  .secure-field--input {
  border: 0;
}

.inline-wrapper.secure-field__has-focus-cvv
  .secure-field.secure-field__base.secure-field__has-card-icon
  .secure-field--card-icon {
  opacity: 0;
  visibility: hidden;
}
.inline-wrapper.field__has-error
  .secure-field.secure-field__base.secure-field__has-card-icon.secure-field
  .secure-field--card-icon.secure-field--card-icon__error,
.inline-wrapper.secure-field__has-focus-cvv
  .secure-field.secure-field__base.secure-field__has-card-icon
  .secure-field--cvv-icon {
  opacity: 1;
  visibility: visible;
}

#expiry {
  font-size: 16px;
  font-family: "Open Sans";
  padding: 11px 10px 10px;
  border: 1px solid #c6c6cb;
  height: 42px;
  top: -1px;
  position: relative;
  margin-left: 70px;
}
#expiry::placeholder {
  font-size: 17px;
}
/* #expiry:focus { outline: none; box-shadow: none; } */

.rsc-ts-image {
  border-radius: 50% !important;
}

.rsc-content {
  display: flex;
  flex-direction: column;
  gap: 5;
  background-color: #f5f5f5;
  height: calc(100vh - 250px) !important;
  padding-bottom: 250px;
}

.rsc-ts-bot {
  margin-bottom: 8px;
}

.rsc-cs {
  box-shadow: none !important;
}

.rsc-ts-user {
  display: flex;
  justify-content: flex-end;
}

.rsc-ts-user > .rsc-ts-bubble {
  width: auto !important;
  max-width: fit-content;
  background-color: #1565c0 !important;
}

.rsc-cs {
  background-color: #f5f5f5 !important;
  padding: 0% !important;
}

@keyframes animate-grow {
  0% {
    width: 0px;
  }
  100% {
    width: 100px;
  }
}

.rsc-ts-user .rsc-loading {
  /* display: none !important; */
  display: inline-block;
  visibility: hidden;
  height: 20px;
  animation-duration: 800ms;
  animation-name: animate-grow;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

#sb-auto-complete {
  border: #f5f5f5;
}
